exports.components = {
  "component---src-pages-1-rm-calculator-js": () => import("./../../../src/pages/1rm-calculator.js" /* webpackChunkName: "component---src-pages-1-rm-calculator-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-online-coaching-js": () => import("./../../../src/pages/online-coaching.js" /* webpackChunkName: "component---src-pages-online-coaching-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-protein-calculator-js": () => import("./../../../src/pages/protein-calculator.js" /* webpackChunkName: "component---src-pages-protein-calculator-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-subscription-js": () => import("./../../../src/pages/subscription.js" /* webpackChunkName: "component---src-pages-subscription-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-websites-js": () => import("./../../../src/pages/websites.js" /* webpackChunkName: "component---src-pages-websites-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

